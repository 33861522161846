import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom'
import tept from './image/TEPT-1.png'
import Home from './Page/Home';
import Search from './Page/Search';
import Philosophers from "./Page/Philosophers";
import Button from 'react-bootstrap/Button';
import React from 'react';
import './App.css';
import Labels from "./Page/Labels";


function App() {
    return (<div className="App">
        <Router>
            {Header()}
            <article>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/search" element={<Search/>}/>
                    <Route path="/phy/:id" element={<Philosophers/>}/>
                    <Route path="/labels" element={<Labels/>}/>
                </Routes>
            </article>
        </Router>
    </div>);
}

function Header() {

    return (<header>
        <span className="buttons1">
            <Link to='/'>
                <Button variant="outline-secondary" className="square border border-5">
                    <img src={tept}
                         alt="Tept"/>
                </Button>
            </Link>
        </span>
        <span className="buttons2">
            <Link to='/search'>
                <Button variant="outline-light">
                    Search
                </Button>
            </Link>
        </span>
        <span className="buttons3">
            <Link to='/labels'>
                <Button variant="outline-light">
                    Labels
                </Button>
            </Link>
        </span>
        <span className="buttons3">
            <Link target="_blank" to='https://dr2blog.hcommons.org/'>
                <Button variant="outline-light">
                    About us
                </Button>
            </Link>
        </span>
    </header>);
}

export default App;

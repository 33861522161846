import dr2 from '../image/DR2_logo.png'
import React from "react";
import {Link} from "react-router-dom";

const desc ='The Tree of Philosophers is a digital repository of information concerning the relationships of academic descent among philosophers: philosopher A was the teacher/supervisor/master/etc. of philosopher B. As can be easily imagined, such relations can take on very different forms, according to the historical period, the geographical area and the institutional setting. The attempt to keep track of such differences is one of the main pecularities of the Tree of Philosophers with respect to other similar undertakings. \n' +
    'The use of the term “tree” may involve some suggestions that are not, rigorously speaking, correct. In fact, it may happen that a philosopher has more than one “master” (multiple supervisors, compresence of different kinds of master-pupil relationships, etc.), so that what one gets is not a simple tree, but a more complex graph, branching in both directions. Furthermore, the data here collected do not give rise to a single connected graph, but rather to a number of unconnected ones. Therefore what is here available could be more correctly called a “polyforest”. Yet the word “tree” has been retained because it seemed more vivid and expressive.\n' +
    'An important feature of the Tree of Philosophers is that it focuses on institutional relationships, that is to say, on relationships that are institutionally recognized, and that can – at least in principle – be documented as such. This means that generic relations of influence, however certain and significant, are not taken into account. The focus is deliberate: the Tree of Philosophers is intended as a resource concerning the institutional (which usually means academic) transmission of philosophy. Keeping this kind of transmission distinct from other, more informal, channels allows for possible comparisons, which would be made impossible by mixing things up. It is probably interesting, for instance, to be able to observe whether the institutional genealogy of philosophy, in specific spatio-temporal circumstances, does or does not approximate the usual historiographic picture of that philosophical context. Moreover, taking generic relations of influence into account would require an appreciable amount of arbitrary decisions for each case. All that would make the Tree of Philosophers the final result of a complex historiographic work, in which the judgments of the editors would play a crucial role. By contrast, we conceive of the tree not as a final result, but as the possible starting point for other researchers; in consideration of this aim, it is certainly better to keep the role of personal judgments, though well meditated, to a minimum.\n' +
    'Some relatively arbitrary decisions are in any case unavoidable as to who is to count as a philosopher (and therefore is to be included in the tree) and who is not. This is of course a very general problem of “field delineation”, which is not exclusive to the tree, but must be faced by anyone who is trying to write, e.g., a history of philosophy. In order to comprehend potentially interesting information on the connections between philosophy and other disciplines, we tried to include “parents” of philosophers who were not themselves philosophers, as well as “children” of philosophers who did not work themselves as philosophers.\n' +
    'Our wish is to make all the data of the Tree of Philosophers available to everybody interested. Different ways to query the database are enabled, but you can contact us for special requests.\n' +
    'The Tree of Philosophers is an ongoing project, which will probably always be in need of correction (some mistakes are certainly still present in the database, notwithstanding our efforts), expansion (adding new philosophers), enrichment (adding new data concerning philosophers and/or their relations) and refinement (devising more accurate ways to represent the relations). Everybody is invited to collaborate.\n'
function Home() {
    return (
        <div className='home'>
            <img src={dr2}
                 alt="Dr2"/>
            <h1>What is the Tree of Philosophers?</h1>
            <p>{desc}</p>
            <Link to='/labels'>
                <p>
                    Labels
                </p>
            </Link>
        </div>
    );
}

export default Home;

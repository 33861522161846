import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Button, Checkbox, Divider,
    FormControl, FormControlLabel, FormLabel,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem, Radio, RadioGroup,
    Select, SelectChangeEvent, Switch,
    TextField, Typography,
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import OutlinedInput from '@mui/material/OutlinedInput';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import {variables} from '../variables';

function Search() {
    const [risQuery, setRisQuery] = useState('')
    const [form, setForm] = React.useState<string[]>(['']);
    const [filters, setFilters] = React.useState<string[]>([]);
    const [notOfFilters, setNotOfFilters] = React.useState<string[]>([]);
    const [applyFilters, setApplyFilters] = useState(false)
    const [and, setAnd] = useState('and')
    const [select, setSelect] = React.useState<string[]>(['name']);

    const navigate = useNavigate();

    const useStylesSwitchFilter = makeStyles({
        root: {
            "& label": {
                marginTop: -3, // fix the icon alignment issue
            }
        },
        label: {
            display: "inline-flex",
            alignItems: "center"
        }
    });

    const classesSwitchFilter = useStylesSwitchFilter();

    const goRouteId = (id: any) => {
        navigate(`/phy/${id}`);
    }

    const handleKeyDown = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            searchQuery()
        }
    };

    const handleChangeForm = (value: string | React.SetStateAction<string[]>, id: number) => {
        const aux = Array(select.length)
        for (let i = 0; i < form.length; i++) {
            if (i !== id) {
                aux[i] = form[i]
            }
        }
        aux[id] = value
        setForm(
            aux
        );
    };

    const handleChangeFilter = (value: string | React.SetStateAction<string[]>, id: number) => {
        if (risQuery.length !== 0) {
            const aux = Array(Object.keys(risQuery[0]).length)
            for (let i = 0; i < filters.length; i++) {
                if (i !== id) {
                    aux[i] = filters[i]
                }
            }
            if (value === "") {
                aux[id] = undefined
            } else {
                aux[id] = value
            }
            setFilters(
                aux
            );
        }
    };

    const handleChangeNotFilter = (id: number) => {
        if (risQuery.length !== 0) {
            const aux = Array(Object.keys(risQuery[0]).length)
            for (let i = 0; i < notOfFilters.length; i++) {
                if (notOfFilters[i] === undefined) {
                    aux[i] = false
                } else {
                    aux[i] = notOfFilters[i]
                }
            }
            aux[id] = !aux[id]
            setNotOfFilters(
                aux
            );
        }
    };

    const formJSX = []

    if (select.length > 0) {
        for (let i = 0; i < select.length; i++) {
            formJSX.push(<TextField id="standard-basic_" label={select[i]} variant="standard" key={'field_' + i}
                                    onChange={e => handleChangeForm(e.target.value, i)}
                                    style={{margin: '1em'}}/>)
        }
    }

    function searchQuery() {
        setApplyFilters(false)
        let arg: string
        if (and === 'and') {
            arg = '?and=1'
        } else {
            arg = '?and=0'
        }
        if (form[0] !== '') {
            for (let i = 0; i < select.length; i++) {
                if (select[i] === 'name') {
                    if (form[i].split('_').length === 2) {
                        let name = Array.from(form[i].split('_')[0]) as any
                        if(name.length>0){
                            name[0] = name[0].toUpperCase()
                        }
                        name = name.join('');
                        let surname = Array.from(form[i].split('_')[1]) as any
                        if(surname.length>0){
                            surname[0] = surname[0].toUpperCase()
                        }
                        surname = surname.join('');
                        arg = arg + '&name=' + name + '&surname=' + surname
                    } else {
                        let surname = Array.from(form[i]) as any
                        if(surname.length>0){
                            surname[0] = surname[0].toUpperCase()
                        }
                        surname = surname.join('');
                        arg = arg + '&surname=' + surname
                    }
                } else if (select[i] === 'university') {
                    let university = Array.from(form[i]) as any
                    if(university.length>0){
                        university[0] = university[0].toUpperCase()
                    }
                    university = university.join('');
                    arg = arg + '&university=' + university
                } else if (select[i] === 'viaf') {
                    arg = arg + '&viaf=' + form[i]
                } else if (select[i] === 'orcid') {
                    arg = arg + '&orcid=' + form[i]
                } else if (select[i] === 'degree_year') {
                    if (form[i].split(' ').length === 2) {
                        arg = arg + '&degree_year=' + form[i].split(' ')[0] + '&degree_year2=' + form[i].split(' ')[1]
                    } else {
                        arg = arg + '&degree_year=' + form[i]
                    }
                } else if (select[i] === 'degree_country') {
                    let degree_country = Array.from(form[i]) as any
                    if(degree_country.length>0){
                        degree_country[0] = degree_country[0].toUpperCase()
                    }
                    degree_country = degree_country.join('');
                    arg = arg + '&degree_country=' + degree_country
                }
            }
        }

        fetch(variables.API_URL + 'philosophers' + arg)
            .then(response => response.json())
            .then(data => {
                setRisQuery(data);
            });
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChangeSelect = (event: SelectChangeEvent) => {
        const {
            target: {value},
        } = event;
        setSelect(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const formSearch = (<>
        <div className='formSearch' style={{display: 'flex', alignSelf: 'center', justifyContent: 'center'}}>
            <FormControl sx={{margin: '1em'}}>
                <FormLabel id="demo-radio-buttons-group-label">Logical Operators</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="and"
                    name="radio-buttons-group"
                    onChange={e => setAnd(e.target.value)}
                >
                    <FormControlLabel value="and" control={<Radio/>} label="And"/>
                    <FormControlLabel value="or" control={<Radio/>} label="Or"/>
                </RadioGroup>
            </FormControl>
            <FormControl sx={{m: 1, minWidth: '12em', marginTop: '2em', maxWidth: '20em'}}>
                <InputLabel htmlFor="grouped-select"><b>Search for what?</b></InputLabel>
                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    label="Search for whatjdojwdojdwojadwo?"
                    multiple
                    // @ts-ignore
                    value={select}
                    onChange={handleChangeSelect}
                    input={<OutlinedInput label="Name"/>}
                    MenuProps={MenuProps}
                    sx={{
                        color: 'black',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'black',
                            border: 2,
                            color: '#535353',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'black'
                        },
                        "& fieldset>legend": {
                            fontSize: "2.2em", //or whatever works for you
                        },
                    }}>
                    <MenuItem value={'name'}>FullName or Surname</MenuItem>
                    <MenuItem value={'university'}>University</MenuItem>
                    <MenuItem value={'viaf'}>Viaf</MenuItem>
                    <MenuItem value={'orcid'}>Orcid</MenuItem>
                    <MenuItem value={'degree_year'}>Degree Year or Year interval</MenuItem>
                    <MenuItem value={'degree_country'}>Degree Country</MenuItem>
                </Select>
            </FormControl>
            <Button variant="contained"
                    style={{
                        height: '3em',
                        marginTop: '2.5em',
                        backgroundColor: '#34af00',
                    }}
                    onClick={() => {
                        searchQuery()
                    }}
                    endIcon={<SearchIcon/>}
            >Search</Button>
        </div>
        <div onKeyDown={handleKeyDown}>
            {formJSX}
        </div>
    </>)

    const ris = <RisSearch/>

    function RisSearch() {
        const style = {
            width: '100%',
            bgcolor: 'background.paper',
        };


        if (risQuery.length !== 0) {
            let listItem = risQuery as any
            listItem = listItem?.map((row: {
                [x: string]: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
            }) => {
                if (applyFilters) {
                    let f = true
                    for (let i = 0; i < Object.keys(risQuery[0]).length; i++) {
                        if(notOfFilters[i] && row[Object.keys(risQuery[0])[i]] === filters[i] && filters[i] !== undefined){
                            f = false
                        }
                        if((!notOfFilters[i] || notOfFilters[i] === undefined) && row[Object.keys(risQuery[0])[i]] !== filters[i] && filters[i] !== undefined){
                            f = false
                        }
                    }
                    if (f) {
                        return (
                            <ListItem button divider key={Number(row[`phil_id`])} onClick={() => goRouteId(row[`phil_id`])}>
                                <ListItemText primary={row['full_name']}/>
                            </ListItem>
                        )
                    } else {
                        return null
                    }
                } else {
                    return (
                        <ListItem button divider key={Number(row[`phil_id`])} onClick={() => goRouteId(row[`phil_id`])}>
                            <ListItemText primary={row['full_name']}/>
                        </ListItem>
                    )
                }
            })
            return (
                <List sx={style} component="nav" aria-label="mailbox folders">
                    {listItem}
                </List>
            )
        } else {
            return null
        }
    }


    const filterForms = []

    if (risQuery.length !== 0) {
        filterForms.push(<Divider key='dividerFilters'
                                  sx={{
                                      "&::before, &::after": {
                                          borderColor: "black",
                                      },
                                  }}
        >
            <Typography>Filter Result By <ArrowDownwardIcon/></Typography>
        </Divider>)

        for (let i = 1; i < Object.keys(risQuery[0]).length; i++) {
            filterForms.push(<TextField id="standard-basic_" label={Object.keys(risQuery[0])[i]} variant="standard"
                                        key={'field_' + i}
                                        onChange={e => handleChangeFilter(e.target.value, i)}
                                        style={{margin: '1em'}}/>)
            filterForms.push(<FormControlLabel key={'not_' + i} onChange={() => handleChangeNotFilter(i)}
                                               style={{marginTop: '2em'}} control={<Checkbox/>} label="not"/>)

        }

        filterForms.push(<div key='filter'>
            <br/>
            <FormControlLabel className={classesSwitchFilter.root} onClick={() => {
                setApplyFilters(!applyFilters)
            }} key='Filter' control={<Switch/>}
                              label={
                                  <div className={classesSwitchFilter.label}>
                                      Apply Filters <FilterAltIcon/>
                                  </div>
                              }/>
        </div>)
        filterForms.push(<Divider key='dividerResult'
                                  sx={{
                                      "&::before, &::after": {
                                          borderColor: "black",
                                      },
                                  }}
        >
            <Typography>Philosophers Found<ArrowDownwardIcon/></Typography>
        </Divider>)
    }
    return (
        <div className='search'>
            {formSearch}
            {filterForms}
            {ris}
        </div>
    );
}

export default Search;

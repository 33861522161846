import React, {useState} from "react";
import {Button, ButtonGroup} from "@mui/material";

const Praeses = [] as any

Praeses.push(<h2>The changing image of the Renaissance university</h2>)
Praeses.push(<p>The system of higher education underwent significant transformations in 16th-century Europe. These
    changes invested the material and institutional features of the European higher instruction network as well as its
    intellectual outlook. New types of learning institutions, such as colleges and gymnasia emerged besides the
    universities. Novel pedagogical conceptions, methods and tools were devised and gradually incorporated into
    teaching. Last but not least, the philosophical disciplines gained more and more centrality within the curriculum as
    they entered a phase of substantial development.
    Historians of early-modern thought have long recognized the complexity of these phenomena, shedding light on the
    variety of the historical actors they involved. Although there was a tendency to interpret these changes as a clash
    between humanistic and scholastic models of learning (Reif 1969; Wallace 1988), studies have shown that this
    transition was more intricate and the relationship between the historical actors who performed it less clear-cut and
    polarized. As a result, the image of the Renaissance university changed from that of a battlefield to that of a
    tremendously dense network of complex relationships. Especially in recent years, this mutated approach has fueled
    new investigations in the history of early-modern philosophy that have highlighted the interplay between traditional
    knowledge and new resources in 16th- and 17th-century thought (Blackwell and Kusukawa 2017; Omodeo and Wels 2019).
    A network-oriented resource like the Tree of Philosophers would make this information more accessible than it
    actually is, being shelved in bulky (and, often, dusty) volumes of impressive scholarly erudition. More importantly,
    the Tree of Philosophers would arguably provide the user with a sharper image of the social and intellectual
    environment of Renaissance universities, i.e., an image whose general feature (i.e., the extension and “shape” of
    the network) and internal structure (i.e., the links between its nodes) are more clearly displayed and immediately
    grasped. In turn, this improved accessibility could become a key resource in exploiting the data for
    historiographical studies of the kind mentioned above.
</p>)
Praeses.push(<h2>Problems with genealogy</h2>)
Praeses.push(<p>Adapting a genealogical scheme of academic relationships, i.e., one that understands them in terms of
    relationships between parents (i.e., professors or masters) and children (i.e., students or pupils), to the
    socio-institutional environment of Renaissance universities poses one obvious problem. To what extent is the schema
    applicable to such an environment? Were there parents and children in Renaissance universities? If so, who were
    they?
    To answer this question, one has to bear in mind some of the distinctive features of the Renaissance system of
    university teaching and knowledge production. To be sure, the two practices that shape the relationship of academic
    filiation as structured in more recent times, namely the awarding of masters’ and/or doctoral degrees and the
    supervising and writing of dissertations, existed in the Renaissance university as well. However, they had different
    meanings and functions than those that we are used to attributing to them. The Renaissance university lacked a
    regimented system of examination of candidates to award degrees. Degrees, and especially the doctorate, functioned
    much more as a social convention to enter a corporation of professionals rather than, as Frijhoff put it, a
    “cognitive sanction” (Frijhoff 1996, 364), that is, an official and unmistakable indication of the quality of the
    knowledge acquired by the candidate in a specific field of learning (Of course, there were also differences that are
    especially relevant from the standpoint of the history of culture than that of the sociology of knowledge. Peculiar
    as it may seem today, bullfighting was an integral part of the celebrations for obtaining a doctorate, especially in
    16th-century Spain). As a consequence, the awarding of degrees did not necessarily coincide with a significant
    moment in knowledge production. Generally speaking, dissertations were not research works as much as they were
    teaching tools before the late 18th- and the early 19th-centuries (Chang 2004; for the later developments see
    Schubart-Fikentscher 1970). In this sense, dissertations were indicative of some sort of relationship between
    masters and students, but not of the kind that the genealogical formula professor-dissertation-student entails and
    aims to assess. Masters assigned topics and texts to analyze to the students (Frijhoff 1996, 376) and, in most
    cases, wrote the text of the dissertations that the students had to defend (Chang 2004, 151–52). All in all,
    dissertations were meant to test the students’ comprehension of authorities and not to train (or exhibit proof of)
    their capacity to conduct research in a given discipline. To understand the practice of the professor writing the
    dissertation, we must keep in mind the original character of the disputation. A disputation was to test the
    candidate's comprehension of the theses of an authority – Aristotle, Galen, or his praeses. It was therefore
    unproblematic that his praeses wrote down the theses for him as long as the candidate was able to sustain the theses
    in front of opponents. As historical documents, therefore, early-modern dissertations provide invaluable insight
    into the pedagogical networks that developed in 16th- and 17th-century universities. But does this kind of
    relationship, i.e., one in which practicing matters more than researching or, that is the same, the pedagogical
    element prevails over the production of new knowledge, qualifies as a parent and child one? Would a genealogical
    representation of relationships of this kind provide us with a resource to develop explanations for phenomena of
    intellectual legacy in the history of thought?
    There are some elements to support this view. As soon as the specificity of the early-modern university system is
    understood, one may argue, the metadata concerning præses and respondens that can be gleaned from dissertations are
    of great importance and could be interpreted from a genealogical perspective. In studying long-term phenomena of
    knowledge evolution in the early-modern period (e.g., conceptual change, framework transformation, methodological
    shifts), the analysis of pedagogical relationships is a fundamental aspect to take into account. Innovations often
    emerged from the reshaping process undergone by the traditional philosophical knowledge on which 16th-century
    university teaching largely relied. It might seem irrelevant to know that Johannes Kepler (1571-1630) had his
    dissertation presided over by Georg Liebler (1543-1583), who held the chair in natural philosophy at University of
    Tübingen from 1553 to 1594. However, this relationship becomes more interesting when one considers the humanistic
    background of Liebler and Kepler’s attitude towards the Aristotelian conception of physics as a science. Liebler
    studied philosophy under the humanistically-trained physician Jacob Schegk (1511-1587) in Tübingen, where he became
    magister artium in 1544 and professor of Greek and Latin in 1547; in the same period in which he taught natural
    philosophy, he also held the chair of rhetoric (on Liebler see Lohr 1978, 542). As Boner (2013, 38) noted, Kepler
    applied biological models of causation to the domain of astronomical entities, in search for a metaphysical
    continuity for the cosmos in terms of an uniform principle of causation. This attitude resonates with the approach
    to natural philosophy teaching that humanistically-trained professors were developing in the 16th-century, namely
    that of broadening the focus of natural philosophy from the study of local motion to that of the vastness and
    complexity of what physics was to Aristotle (see Blair 2016). To figure out a historically sound way in identifying
    parents and children in Renaissance university, then, it is essential to realize how the evolution of philosophical
    knowledge in that period arises from the tensions that originated and accumulated within traditional philosophical
    knowledge, and how pedagogy (in all its forms, one of which is the dissertation) played a role in triggering and
    directing those tensions. Moreover, the authorship of the dissertation is itself a historical variable in the
    early-modern period. There is evidence that students began to write themselves the text of dissertations from the
    late 17th-century (Cheng 2004, 151). This aspect might add more nuances to the parent-child relationship in
    early-modern universities than highlighted here.
    Needless to say, there are some difficulties. Besides being an opaque object from the historical point of view,
    dissertations are also documents that should be inspected in accordance with catalogs and university registers of
    graduate students, which often lack clear and useful information in this respect (Frijhoff 1996, 377). </p>)

Praeses.push(<h2>So what to do?</h2>)
Praeses.push(<p>Early-modern university dissertations have received more and more scholarly attention over the past few
    years (Friedenthal, Marti, and Seidel 2021). In particular, Stefan Heßbrüggen-Walter has recently started a research
    project entirely focused on university dissertations written in France between the 16th and the 18th century, in the
    scope of the project The Afterlife of a Renaissance Genre: A Census of Dissertations in French Libraries 1500-1800.
    This project could reinforce the importance that university dissertations have acquired in the study of early-modern
    philosophy and better clarify how this genre contributed to the development of early-modern philosophical knowledge,
    besides bringing to light new metadata to analyze and interconnect in a genealogical scheme of the kind of the Tree
    of Philosophers.
    An interesting approach to explore would be that of combining a network-focused study of early-modern university
    dissertations with that of the production of university textbooks. From a knowledge-production perspective,
    textbooks can be considered as the most important output of 16th-century academic culture (Schmitt 1988, Kusukawa
    1995). At a first glance, they retain a general Aristotelian philosophical setting. On closer inspection, they
    reveal how this framework was slowly but significantly changing. The study of textbooks from a sociological
    perspective could open up to an intriguing investigation into how multiple interests and approaches convened in
    defining the ambivalent identity of early-modern university philosophy, so suspended between tradition and novelty.
</p>)

Praeses.push(<h2>References</h2>)
Praeses.push(<p>Bataillon, Marcel. 1991. Érasme et l’Espagne. Edited by Daniel Devoto and Charles Amiel. Genève:
    Librairie Droz. <br/>
    Blackwell, Constance, and Sachiko Kusukawa. 2017. Philosophy in the Sixteenth and Seventeenth Centuries:
    Conversations with Aristotle. Routledge.<br/>
    Blair, Ann. 2016. ‘Natural Philosophy’. In The Cambridge History of Science. Volume 3. Early Modern Science, edited
    by Katharine Park and Lorraine Daston, 3:365–406. The Cambridge History of Science. New York: Cambridge University
    Press.<br/>
    Boner, Patrick J. 2013. Kepler’s Cosmological Synthesis: Astrology, Mechanism and the Soul. Medieval and Early
    Modern Science 20. Leiden-Boston: BRILL.<br/>
    Chang, Ku-ming (Kevin). 2004. ‘From Oral Disputation to Written Text: The Transformation of the Dissertation in
    Early Modern Europe’. In History of Universities, edited by Mordechai Feingold, XIX/2:129–87. History of
    Universities Series. Oxford: Oxford University Press.<br/>
    Davies, Jonathan. 2009. Culture and Power: Tuscany and Its Universities 1537-1609. Education and Society in the
    Middle Ages and Renaissance 3. Leiden; Boston: BRILL.<br/>
    Freedman, Joseph S. 1999. Philosophy and the Arts in Central Europe, 1500-1700: Teaching and Texts at Schools and
    Universities. London and New York: Routledge.
    http://archive.org/details/philosophy-and-the-arts-in-central-europe-1500-1700.<br/>
    Friedenthal, Meelis, Hanspeter Marti, and Robert Seidel, eds. 2021. Early Modern Disputations and Dissertations in
    an Interdisciplinary and European Context. Leiden; Boston: BRILL.<br/>
    Frijhoff, Willem. 1996. ‘Graduation and Careers’. In A History of University in Europe, edited by Walter Rüegg and
    Hilde de Ridder-Symoens. Vol. Volume II: Universities in Early Modern Europe (1500-1800). Cambridge, New York,
    Oakleigh: Cambridge University Press.<br/>
    Gilbert, Neal W. 1963. Renaissance Concepts of Method. New York and London: Columbia University Press.
    http://archive.org/details/renaissanceconce0000gilb.<br/>
    Hotson, Howard. 2007. Commonplace Learning: Ramism and Its German Ramifications, 1543-1630. Oxford University Press.<br/>
    ———. 2020. The Reformation of Common Learning: Post-Ramist Method and the Reception of the New Philosophy, 1618 -
    1670. OUP Oxford.<br/>
    Kenny, Anthony, and Jan Pinborg. 1982. ‘Medieval Philosophical Literature’. In The Cambridge History of Later
    Medieval Philosophy. From the Rediscovery of Aristotle to the Disintegration of Scholasticism 1100-1600, edited by
    Norman Kretzmann, Anthony Kenny, and Jan Pinborg, 9–42. Cambridge: Cambridge University Press.<br/>
    Lechner, Joan Marie. 1962. Renaissance Concepts of the Commonplaces: An Historical Investigation of the General and
    Universal Ideas Used in All Argumentation and Persuasion with Special Emphasis on the Educational and Literary
    Tradition of the Sixteenth and Seventeenth Centuries. New York: Pageant Press.<br/>
    Leff, Gordon. 1992. ‘The Faculty of Arts’. In Universities in the Middle Ages, edited by Hilde de Ridder-Symoens,
    1:307–36. A History of the University in Europe. Cambridge: Cambridge University Press.<br/>
    Lohr, Charles H. 1974. ‘Renaissance Latin Aristotle Commentaries: Authors A-B’. Studies in the Renaissance 21:
    228–89. https://doi.org/10.2307/2857156.<br/>
    ———. 1975. ‘Renaissance Latin Aristotle Commentaries: Authors C’. Renaissance Quarterly 28 (04): 689–741.
    https://doi.org/10.2307/2860175.<br/>
    ———. 1976. ‘Renaissance Latin Aristotle Commentaries: Authors D-F’. Renaissance Quarterly 29 (4): 714–45.<br/>
    ———. 1978. ‘Renaissance Latin Aristotle Commentaries: Authors L-M.’ Renaissance Quarterly 31 (4): 532–603.
    https://doi.org/10.2307/2860377.<br/>
    ———. 1979. ‘Renaissance Latin Aristotle Commentaries: Authors N-Ph’. Renaissance Quarterly 32 (4): 529–80.
    https://doi.org/10.2307/2861415.<br/>
    ———. 1980. ‘Renaissance Latin Aristotle Commentaries: Authors Pi-Sm’. Renaissance Quarterly 33 (4): 623–734.
    https://doi.org/10.2307/2860691.<br/>
    ———. 1982. ‘Renaissance Latin Aristotle Commentaries: Authors So--Z’. Renaissance Quarterly 35 (2): 164–256.
    https://doi.org/10.2307/2861036.<br/>
    Omodeo, Pietro Daniel, and Volkhard Wels. 2019. Natural Knowledge and Aristotelianism at Early Modern Protestant
    Universities: 14. Wiesbaden: Otto Harrassowitz.<br/>
    Ong, Walter J. 1958. Ramus, Method, and the Decay of Dialogue: From the Art of Discourse to the Art of Reason.
    Cambridge, Massachusetts: Harvard University Press.<br/>
    Reif, Patricia. 1969. ‘The Textbook Tradition in Natural Philosophy, 1600-1650’. Journal of the History of Ideas 30
    (1): 17–32. https://doi.org/10.2307/2708242.<br/>
    Reinhard, Wolfgang, ed. 1984. Humanismus Im Bildungswesen Des 15. Und 16. Jahrhunderts. Mitteilung Der Kommission
    Für Humanismusforschung, XII. Acta Humaniora.<br/>
    Ridder-Symoens, Hilde de. 1996. ‘Management and Resources’. In Universities in Early Modern Europe (1500-1800),
    edited by Walter Rüegg and Hilde de Ridder-Symoens, 2:154–209. A History of the University in Europe. Cambridge:
    Cambridge University Press.<br/>
    Rüegg, Walter. 1996. ‘Themes’. In Universities in Early Modern Europe (1500-1800), edited by Walter Rüegg and Hilde
    de Ridder-Symoens, 2:3–42. A History of the University in Europe. Cambridge: Cambridge University Press.<br/>
    Scholz, Luca. 2022. ‘A Distant Reading of Legal Dissertations from German Universities in the Seventeenth Century’.
    The Historical Journal 65 (2): 297–327. https://doi.org/10.1017/S0018246X2100011X.<br/>
    Schubart-Fikentscher, Gertrud. 1970. Untersuchungen zur Autorschaft von Dissertationen im Zeitalter der Aufklärung.
    Akademie-Verlag.<br/>
    Wallace, William A. 1988. ‘Traditional Natural Philosophy’. In The Cambridge History of Renaissance Philosophy,
    edited by Charles B. Schmitt, Quentin Skinner, Eckhard Kessler, and Jill Kraye, 201–35. New York: Cambridge
    University Press.</p>)

const ens = [] as any

ens.push(<p>Trying to find in 19th-century France an institutional master-pupil relationship based on the present state
    of university organisation would constitute an anachronism. Currently, this relationship is mainly exemplified by
    the relationship between the professor supervising the doctoral thesis and the doctoral student. The first part of
    this text is expected to show why this relationship cannot be traced in the place and time considered. Then,
    excluding other types of relationships that could be considered, the peculiarities of the French university
    institution in the 19th century will be set out, claryfing how the relation of training for the exam of Aggrégation
    at the ENS determines a form of a master-pupil relationship in that context.
</p>)
ens.push(<h2>Pars destruens. The strange landscape of the French university in the 19th century</h2>)

ens.push(<p>As we said, the first attempt from our perspective is to look for the relationship between the supervisor
    and the doctoral student. Well, this relationship in the French university institution of the 19th century is
    absent. The role of the doctoral thesis supervisor (directeur de thèse) was only legally established in 1969
    (Verschueren & Obligi, 2021). In France, two doctoral theses were prepared, one in Latin and one in French, from
    1808 onwards (de Beauchamp, 1880, p. 174). The length of these theses increased significantly over the 19th century,
    reflecting the changing significance of these works (Verschueren & Obligi, 2021). If the degree of doctor is
    immediately necessary to be able to aspire to a career in higher education, the value of the theses and their
    discussion changes. Initially, candidates discussed two short theses of around twenty pages. They discussed, in the
    literal sense of the term: the important thing was the rhetorical skills exhibited in the discussion of the thesis.
    Later, theses began to be conceived as the first original work of a university professor or professional researcher.
    In the absence of indications that can provide us with the systematic form of a master - pupil relationship in the
    context of the doctoral thesis, we can think of turning our gaze towards the careers of the teaching staff of French
    universities (Charle, 1985a, 1985b; Karady, 1985). The hierarchical organisation of professors into full professors,
    associate professors and substitute professors might suggest a master-pupil relationship between, for example, the
    holder of the chair and the professor who provides the replacement. However, the appointments of professors at each
    grade were made directly by the Minister of Education. Consultation of the application files submitted by candidates
    for vacancies also reveals that letters of reference were mainly written by politicians. Ministers, members of
    Parliament, and senators wrote the candidates’ letters of recommendation in most cases. Only a few were written by
    university professors, and mainly at the turn of the century [See the following folders kept at the Archives
    nationales-site de Pierrefitte-sur-Seine in the section of the Ministry of Public Education: F/17/1418; F/17/1419;
    F/17/13111; F/17/13112; F/17/13113; F/17/13114; F/17/13115]. This makes it difficult to trace a systematic
    relationship from these and takes us beyond the period under consideration.
    One could then think, as a last resort, that the professor (or professors) of philosophy in the humanities faculty
    (faculté) could be systematically regarded as the teachers of those who obtained their degree (licence) in that
    faculty and subsequently embarked on an academic career. The reason why this relationship would not be historically
    valid lies in the role that universities played throughout most of the 19th century in France. The main function of
    the faculties was to confer the degrees necessary for career advancement. The professors of the Faculty of Letters
    thus constitute the members of the juries for the various degrees and diplomas obtainable in that field of study:
    the diploma of secondary studies (baccalauréat ès lettres), the degree (licence ès lettres), and the PhD (doctorat
    ès lettres) (Karady, 1986; Prost, 1968, pp. 223‑224). Degree (licence) candidates prepared their examinations
    autonomously. The courses that professors gave at the university had no connection with exam and competition
    preparation. The courses were mainly listened to by an audience, not a class of students, at least until the last
    two decades of the 19th century (Noguès, 2008). It would therefore make no sense from a historical point of view to
    think that the professor (or professors) of philosophy in the humanities faculty could be systematically regarded as
    the teachers of those who obtained their degree in that faculty.</p>)

ens.push(<h2>Pars construens. The École normale supérieure and the reproduction of the university elite</h2>)

ens.push(<p>Let us now move on to the description of the path that have been followed and the way the relationship
    system was set up.
    Excluding all the above possibilities, what is left? The decree organising the imperial university in 1808
    stipulated the reopening of a pensionnat normal, later École normale supérieure, intended as the training place for
    future professors (de Beauchamp, 1880, p. 186), and constituting one of the reasons for Paris’s predominance on the
    French university scene (Karady, 1986; Prost, 1968, p. 72).
    It was usual, after the École normale, to apply for the agrégation, the national competition for positions in the
    teaching system. The agrégation is thus the threshold that allows access to the French teaching system, even though
    a doctorate, which is normally obtained after the agrégation, is also required in order to teach at university. Most
    of those who passed the competition for philosophy (and other disciplines) were fresh out of the École normale
    (Bourgeois, 1994; Chervel, 2015; Fabiani, 1988). As we have said, in the Faculty of Letters, teaching was aimed at a
    varied audience and had no relation to the examinations to be taken. The same applies to agrégation. After
    graduation, candidates prepared for the competition individually. At the École normale, it was different. Teaching
    at the École normale consisted of the activity of giving conférence. Like the seminar at German universities, the
    conférence consisted of a first part, in which the professor gave a lecture, and a second and extensive part, in
    which students discussed among themselves and with the professor, who took the role of moderator. The number of
    students was limited (from about four to ten), and this practice ensured the formation of a personal teaching
    relationship between the professor and the students (as well as a horizontal relationship between students) (Hummel,
    1995, pp. 59‑82). It is precisely this relationship between the lecturer at the École normale and the student taking
    the conférence that is identified as a form of a master-pupil relationship in 19-century France philosophical
    institution.
    The way the relationships are set up is as follows. First, a choice about time frame to focus on is needed. The
    considered period start in 1810, the year in which the first class of pupils entered the École normale (Association
    des anciens élèves, élèves et amis de l’École normale supérieure, 2015, p. 520). Ideally, the period would have
    finished with 1878. From that year, conférences were also established in the faculties, modelled on the École
    normale and the seminars of German universities, to meet the needs of an audience of students and not auditors
    (Noguès, 2008). However, the students of Ollé-Laprune and Boutroux, who entered the École normale after this year,
    are included in the database. The two professors, in fact, started teaching at the École normale before 1878 (in
    1875 and 1877 respectively), and it seemed appropriate to include all their students up to the year they left their
    teaching at the École normale (in 1898 and 1887 respectively).
    Secondly, a domain of individuals is individuated, to which the relationship are applied. All those who, after
    studying at the École normale (Association des anciens élèves, élèves et amis de l’École normale supérieure, 2015),
    obtained at least one position as a professor of philosophy in higher education (Huguet & Noguès, 2011a, 2011b), or
    wrote a doctoral thesis (Huguet, 2009) and obtained an agrégation in philosophy (Chervel, 2015) are included in the
    database. The few philosophers who taught at the École normale without fulfilling the previous requirements
    (Association des anciens élèves, élèves et amis de l’École normale supérieure, 2015, p. 763) have been included so
    that the terms of the relationships would not be missing.
    Subsequently, the students at the École normale have been linked to the two philosophy professors who taught there
    during their period of attendance. Some clarifications in this regard. Those students who entered the École normale
    up to two years before a given professor took up his post are marked as students of the professor. They could in
    fact have followed his conférence in their third year. Exceptions are those who taught or studied at the École
    normale between 1810 and 1815, a period when the course of study lasted only two years (de Beauchamp, 1880, pp. 186,
    271, 379).
    Students who entered the École normale in the year the professor stopped teaching there are not marked as their
    students. In fact, it is likely that the professor held his last conférence before the new pupils entered. As for
    the years of service of professors at the École normale, one exception must be noted. According to yearbooks
    (Association des anciens élèves, élèves et amis de l’École normale supérieure, 2015, p. 763), databases (Huguet &
    Noguès, 2011b), retirement files [See the following folder kept at the Archives nationales-site de
    Pierrefitte-sur-Seine in the section of the Ministry of Public Education, which contains Victor Cousin’s retirement
    files: F/17/20492] and more, Victor Cousin only taught philosophy at the École normale between 1815 and 1822, the
    year of his suspension due to his liberal political ideas. Until his reinstatement in 1828, he would only teach
    courses in the history of philosophy at the Faculty of Letters in Paris. However, some reliable testimonies claim
    that he also taught at the École normale from 1828 to 1837, holding the third-year conférence there in preparation
    for the agrégation (Simon, 1887, p. 72). He would later stop holding this conférence to avoid the conflict of
    interest that arose from his presidency of the philosophy agrégation jury (Simon, 1887, pp. 72 ff). At that time,
    Cousin was also director of the École normale (Association des anciens élèves, élèves et amis de l’École normale
    supérieure, 2015, p. 759), which may explain the absence of any documentable traces of his teaching in those years.
    Moreover, the professors in charge of teaching at the École normale were his students and members of his régiment.
    therefore the students who entered the École normale between 1826 and 1836 are as well marked as Cousin’s pupils,
    following the criteria set out above.
</p>)

ens.push(<h2>References</h2>)

ens.push(<p>Association des anciens élèves, élèves et amis de l’École normale supérieure. (2015). Supplément historique
    2015.<br/>
    Bourgeois, B. (1994). L’École normale, école philosophique. In J.-F. Sirinelli, R. Rémond, & É. Guyon, Ecole normale
    supérieure : Le livre du bicentenaire (p. 275‑292). Presses universitaires de France.<br/>
    Charle, C. (1985a). Les professeurs de la faculté des lettres de Paris – Dictionnaire biographique 1809-1908 (Vol.
    1). Institut national de recherche pédagogique Éd. du C.N.R.S.<br/>
    Charle, C. (1985b). Les professeurs de la faculté des lettres de Paris – Dictionnaire biographique 1909-1939 (Vol.
    2). Institut national de recherche pédagogique Éd. du C.N.R.S.<br/>
    Chervel, A. (2015). Les agrégés de l’enseignement secondaire. Répertoire 1809-1960. online.<br/>
    de Beauchamp, A. (1880). Recueil des lois et règlements sur l’enseignement supérieur (Vol. 1). Delalain frères.<br/>
    Fabiani, J.-L. (1988). Les philosophes de la république. Les éditions de minuit.<br/>
    Huguet, F. (2009). Les thèses de doctorat ès lettres soutenues en France de la fin du XVIIIe siècle à 1940.
    online.<br/>
    Huguet, F., & Noguès, B. (2011a). Les chaires des facultés de lettres et de sciences en France au XIXe siècle.
    online.<br/>
    Huguet, F., & Noguès, B. (2011b). Les professeurs des facultés des lettres et des sciences en France au XIXe siècle
    (1808-1880). online.<br/>
    Hummel, P. (1995). Humanités normaliennes. L’enseignement classique et l’érudition philologique dans l’École normale
    supérieure du XIXe siècle. Les Belles Lettres.<br/>
    Karady, V. (1985). Lettres et sciences. Effets de structure dans la sélection et la carrière des professeurs de
    faculté (1810-1914). In C. Charle & R. Ferré (Éds.), Le Personnel de l’enseignement supérieur en France aux XIXe et
    XXe siècles : Colloque (p. 29‑46). Editions du Centre national de la recherche scientifique.<br/>
    Karady, V. (1986). De Napoléon à Duruy : Origines et naissance de l’université contemporaine. In J. Verger (Éd.),
    Histoire des universités en France (p. 261‑322). Privat.<br/>
    Noguès, B. (2008). Élèves ou auditeurs ? Le public des facultés de lettres et de sciences au XIXe siècle
    (1808-1878). Histoire de l’éducation, 120, 77‑97. https://doi.org/10.4000/histoire-education.1834<br/>
    Prost, A. (1968). L’enseignement en France 1800-1967 (2e éd.). Armand Colin.<br/>
    Simon, J. (1887). Victor Cousin. Hachette.<br/>
    Verschueren, P., & Obligi, C. (2021). Le projet ès lettres : Premiers jalons pour une socio-histoire des docteurs ès
    lettres. Revue d’histoire des sciences humaines, 39, 239‑252. https://doi.org/10.4000/rhsh.6672
</p>)


const oxford = [] as any

oxford.push(<h2>Wittgenstein’s case</h2>)

oxford.push(<p>As is well known, analytical philosophy was born in the early 20th century, when Bertrand Russell and
    George Edward Moore distanced themselves from British Idealism at Trinity College, Cambridge. In the 1910s, the
    young Ludwig Wittgenstein arrived at Cambridge in order to study with Russell. In 1914, Wittgenstein went to war and
    completed writing his Tractatus logico-philosophicus. He only returned to Cambridge in 1929, once the Tractatus had
    become a famous book, following the initiative of Frank Ramsey and with the help of John Maynard Keynes. Discussing
    the Tractatus with Russell and Moore, Wittgenstein was awarded the degree of Doctor of Philosophy. He then remained
    at Cambridge for the rest of his life, teaching for a few years there. His work provided the impetus for the birth
    and development of (at least) two philosophical traditions: the Oxford ordinary language philosophy and the logical
    empiricism of the Vienna Circle.
    How is this story represented in the Tree of Philosophers? If we restrict ourselves to recording master-pupil
    relationships of an institutional or academic kind, Wittgenstein turns out to be the ‘son’ of F.P. Ramsey, the
    mathematician-philosopher who, when Wittgenstein returned to Trinity College in 1929, was assigned to him as
    supervisor. Ramsey was eleven years younger than Wittgenstein, and was a reader, admirer and translator of the
    Tractatus. That supervision was in many ways purely formal and in any case rather sui generis.
    Wanting to represent Wittgenstein on the tree as Russell’s “son” (a reasonable choice, but a necessary one?) we must
    introduce a new, unorthodox and decidedly ad hoc relationship: the relationship of being a member of the committee
    that at Cambridge evaluates the candidate when awarding the degree of Doctor of Philosophy. Applying this criterion,
    Moore also turns out to be Wittgenstein’s “father” in the same way.
    In general, academic and institutional relations in the British context had a different, and in many respects
    lesser, weight than the paradigmatic case (for the Tree of Philosophers) of the PhD supervision relationship at a
    post-World War II US university. In Great Britain, and particularly at Cambridge and Oxford, personal and informal
    relationships counted more, and, at the institutional level, alongside lectures and seminars, also tutoring
    (Oxford’s “tutorials” and Cambridge's “supervisions”) counted more.
</p>)

oxford.push(<h2>Ryle’s case</h2>)

oxford.push(<p>In 1913 the University of Oxford declared philosophy as an administratively autonomous discipline, and
    provided it with the status of “sub-faculty” of the Literae Humaniores faculty (LH, or Classics or Greats). Since
    1920, in addition to this more classical degree, the more modern PPE, i.e. Philosophy, Politics and Economics, was
    introduced. During the 1950s – when Oxford was regarded by many as “the most important center of philosophy in the
    world” (Searle 2015) – the first post-graduate programs (Dphil) were born.
    In the period from the 1930s to the 1960s, at Oxford the so-called ordinary language philosophy flourished. Its
    fathers were, besides Wittgenstein, Gilbert Ryle (1900-1976) and J.L. Austin (1911-1960).
    Gilbert Ryle is another very relevant figure for those who, like us, want to analyse Oxford philosophy from the
    point of view of academic genealogical relations. In some of his autobiographical writings, Ryle — who was an
    important and powerful academic figure – provides some very useful insights. Let us consider two of them:
    1) The key academic master-pupil relation at Oxford was that of tutoring (‘being tutor of’);
    2) Ryle's teacher and tutor was H.J. Paton.
    We checked in various ways the accuracy of our initial information relating to the institutional context of
    philosophy at Oxford in the days of ordinary language philosophy and, in particular, the accuracy of Ryle’s
    indications: by interviewing professors (Lesley Brown), archivists (Emily Jennings of the Magdalen College) and
    former students (Nikhil Krishnan, Jack Wearing) at Oxford University, and by reading books on the history of
    philosophy and the history of Oxford University.
    We established two firm points:
    1) the fact that Paton was indeed Ryle's teacher (tutor);
    2) the centrality of tutoring. (On this last point, we may recall how Richard Hare, several years later, in the
    early sixties, made a joke to a “New Yorker” journalist: “The thing wrong with the […] Continental philosophers
    [...] is that they haven’t had their noses rubbed in the necessity of saying exactly what they mean. I sometimes
    think it’s because they don’t have a tutorial system”. Ryle would have also subscribed this view).
    Last summer, one of us, Paolo Babbiotti, personally travelled to Oxford. An analysis of the archives yielded a lot
    of interesting data for the reconstruction of this part of the Tree of Philosophers and, more generally, of the
    history of Oxford ordinary language philosophy.
    Ryle was tutor and then supervisor of many students. Our reconstruction of this “Rylean” part of the tree is still
    partial and incomplete, and will therefore not be presented here. We merely point out an extremely interesting case
    – as it has been rather neglected by scholars for the time being – for historians of analytic philosophy: the
    American philosopher Burton Dreben, an important figure in post-World War II Harvard analytic philosophy, was
    supervised by Ryle at Oxford in 1950.
</p>)

oxford.push(<h2>Austin’s case</h2>)

oxford.push(<p>The most interesting case, however, is that of J.L. Austin.
    Every book on the history of analytic philosophy or the history of ordinary language philosophy claims that Austin’s
    teacher was Harold Arthur Prichard. It is likely that the ultimate source of this belief is Isaiah Berlin’s
    authoritative statement in his Personal Impressions: Berlin was Austin’s pupil (not in a strictly academic sense)
    and friend, regularly attending his Saturday meetings. The conviction that Austin was a pupil of Prichard, together
    with the observation that Prichard in turn belonged to the school of John Cook Wilson, has led some scholars, such
    as Tim Williamson, to emphasise Austin’s philosophical diversity from much of the linguistic tradition of Oxford
    philosophy, a diversity mainly related to his adherence to direct and robust forms of realism (Marion 2022). Again
    in Nikhil Krishnan’s very recent and excellent book, A Terribly Serious Adventure: Philosophy at Oxford 1900-60,
    published in early 2023 and also very accurate in its reconstruction of the intellectual biographies of the ordinary
    language philosophers, it is claimed that Austin had two tutors: C.G. Stone, the author of The Social Contract of
    the Universe, and, indeed, Prichard.
    In May 2023, the first biography of J.L. Austin was published by Oxford University Press. A few months before its
    publication, we contacted its author, Mark Rowe. Rowe was a very valuable source for us and a turning point for our
    research, not only because he helped us to understand better the world of tutorials in Oxford colleges, but also and
    especially because he revealed to us that Prichard was never officially Austin’s tutor.
    According to Rowe’s reconstruction, in his Literae Humaniores course of study Austin had four tutors: D.C. Macgregor
    (1888-1939) for Greek history; D.J. Allan (1907-78) for ancient philosophy; C.G. Stone (1886-1976) for Roman
    history; and C.R. Morris [later Baron Morris of Grasmere](1898-1990) for modern philosophy.
    Reflecting on this new information, and also keeping in mind the above mentioned considerations of Ryle on related
    topics, we have concluded that, to represent on the Tree of Philosophers this part of Oxford ordinary language
    philosophy, and J.L. Austin’s genealogy in particular, the crucial relationship — at least until the introduction of
    post-graduate studies in the 1950s — is the relationship to be tutored by x, in philosophy (or philosophical
    subjects). Our proposal is therefore that only Allan and Morris should turn out to be Austin’s “fathers” in the Tree
    of Philosophers.
    With this methodological conviction in mind and with the aim of reconstructing adequately the part of the tree
    relating to Austin’s “sons”, Babbiotti went to the colleges in which Austin had worked, and in particular –
    following the indications of very helpful archivists – to those in which he had been a tutor in philosophy.
    Babbiotti photographed hundreds of documents. The data we obtained from analysing those documents are very
    interesting, and form the material basis for compiling this portion of the tree. We will present in particular the
    data collected at Magdalen College because they are those that we were able to collect, check, clean and sort most
    rigorously and accurately.
    The following image shows a representation of a part of J.L. Austin’s tree relating to the period 1939-1951 and
    reconstructed – wrongly, at least in part – on the basis of a generic father-son relation, whose origins are mostly
    unknown, the kind of relation we find in history of philosophy handbooks and in autobiographical writings.<br/>
    <pre>
    J. Cook Wilson<br/>
    &emsp;    H.A. Prichard<br/>
    &emsp;&emsp;        J.L. Austin<br/>
    &emsp;&emsp;            Bill Bonney<br/>
    &emsp;&emsp;        David Gauthier<br/>
    &emsp;&emsp;        Graham Nerlich<br/>
    &emsp;&emsp;            Annette Baier<br/>
    &emsp;&emsp;        Stanley Cavell<br/>
    &emsp;&emsp;            Mary Warnock<br/>
    </pre>
    The following is a representation of the same part of Austin’s tree reconstructed according to our methodological
    proposal, on the basis of an institutional relationship that can be precisely identified in the documents deposited
    in the archives of Magdalen College, Oxford: the relationship of being a philosophy tutor in the PPE programme
    courses.
    (For each of these students Austin was the only tutor in philosophy, and we also know in which year(s) this
    occurred. We are also in possession of other data from other colleges, but we need to go back to Oxford to collect
    it better and integrate it with the data we already have.)<br/>
    <pre>
    C.R. Morris<br/>
    D.J. Allan<br/>
    &emsp;J.L. Austin<br/>
    &emsp;&emsp;A. Schonfield, R.J.C. Atkinson, J.R. Ballingal, C.S. Cowan, I.A Dannreuther, J.P. de C. Day, J.A.
    Hargeaves, J. Russell, V.I. Williams,<br/>&emsp; H.A. Clegg, R. Glasser, H.B. Nichols, H.R. Stewart, H.N. Winkle, T.M.C.
    Bodley, E. Marsh, J.R. Rann, V.M. Shaw, H.P. Dixon,<br/>&emsp; T.J.O. Hickey, R.S. Hirst, C.M. Kraay, B. Mayo, T.R. Miles, J.H.
    Simon, R.J. Spilsbury, G.S.M. Walker, D. Halewood, J.P.U. Lilley,<br/>&emsp; M.J.M. Paton, J.M.G. Andrews, J.W. Fraser, W.R.
    Sargaison, N.S. Sutherland, J.L. Bullard, M.L. Cahill, R.H. Ellingworth, D. Gallop,<br/>&emsp; D.A. Howell, A.M. Newman, D.L.
    Stockton, G.D. Buchanan, H.F. Duckworth, S. Saba, W.L. Ainsworth, A.K. Forter, D.H. Jordan, G.R. Tibbets,<br/>&emsp; J.E.B.
    Wells, Hon.I. Balfour, E.J. Parsons, G.M. Thomas, M.G. Johnson, R.C. Overton, T.R. Pogson, H.R.I. Sackville-West,<br/>&emsp;
    M.J.G. Sandercock, W.S. Smith, J.W. Adams, A.F. Bell, E.C. Chapman, F. Fenton, C.D. Horton, E.J. Lemmon,<br/>&emsp; D. Rundle,
    A.W. Tyson, E.Z, Swift. <br/>
    </pre>
    It has not been possible so far to reconstruct the identity of all of Austin’s “children” at Magdalen College. But
    from the information we have been able to find so far, it is clear that the philosopher tree, with its institutional
    genealogy, has great explanatory potential for historians of philosophy: it makes it possible to reconstruct, at
    least initially, at a conjectural level, another history – contextual, social, broad – of ordinary language
    philosophy, a history that so far no one has written down but which, together with a number of other factors, may in
    the future help to explain why a way of philosophizing that was in many ways so bizarre, idiosyncratic and in a
    certain sense non-progressive was able to establish itself, for some decades, in England — but only in England — as
    the dominant tradition. An example of the possible reconstruction of the richer context in which ordinary language
    philosophy grew up is provided by the professions of some of Austin’s children listed above: there are only a few
    philosophers (among them J.P. de C. Day, B. Mayo, R.J. Spilsbury, D. Gallop, E.J. Lemmon), and also archeologists,
    economists, poets, international affairs scholars, psychologists, historians of the Arab world, latinists, priests
    and scholars in religious studies, historians of the ancient world (Rome, Egypt, etc.), journalists, translators,
    public administration managers, and so forth.
</p>)

function Labels() {
    const [pageView, setPageView] = useState([false, false, false])

    if (pageView[0]) {
        return (
            <div className='home'>
                {getButtonGroup()}
                <h1>Praeses</h1>
                <h2>Giuseppe Pignatelli</h2>
                {Praeses}
            </div>
        );
    } else if (pageView[1]) {
        return (
            <div className='home'>
                {getButtonGroup()}
                <h1>ENS Aggrégation Training</h1>
                <h2>Alessandro Taverniti</h2>
                {ens}
            </div>
        )
    } else if (pageView[2]) {
        return (
            <div className='home'>
                {getButtonGroup()}
                <h1>Oxford tutorial</h1>
                <h2>Paolo Tripodi – Paolo Babbiotti</h2>
                {oxford}
            </div>
        )
    } else {
        return (
            <div className='home'>
                {getButtonGroup()}
            </div>
        )
    }

    function getButtonGroup() {
        return <ButtonGroup className={'ButtonGroup'} variant="contained" aria-label="outlined primary button group">
            <Button onClick={() => setPageView([true, false, false])}>Praeses</Button>
            <Button onClick={() => setPageView([false, true, false])}>ENS Aggrégation Training</Button>
            <Button onClick={() => setPageView([false, false, true])}>Oxford tutorial</Button>
        </ButtonGroup>;
    }
}

export default Labels;

import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {variables} from "../variables";
import Graphviz from "graphviz-react";
import {CSVLink} from 'react-csv';
import downloadCsv from '../image/csv-download.png'
import {
    Button,
    ButtonGroup,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup, LinearProgress,
    List,
    ListItemText
} from "@mui/material";

function Philosophers() {
    let {id} = useParams();
    const query = Number(id)

    const [pageView, setPageView] = useState([false, true])
    const [dataPhi, setDataPhi] = useState('') as any
    const [sourcePhi, setSourcePhi] = useState('') as any
    const [targetPhi, setTargetPhi] = useState('') as any
    const [auxPhiSource, setAuxPhiSource] = useState('') as any
    const [auxPhiTarget, setAuxPhiTarget] = useState('') as any
    const [allPhiSource, setAllPhiSource] = useState('') as any
    const [allPhiTarget, setAllPhiTarget] = useState('') as any
    const [relCheckBox, setRelCheckBox] = useState({}) as any

    const color = ['firebrick', 'blue', 'aqua', ' chartreuse', 'darkviolet', 'gold', 'olive', 'salmon1', 'tomato', 'thistle']

    let arg = ''
    if (id !== '') {
        arg = '?id=' + query
    }

    const navigate = useNavigate();

    const goRouteId = (id: any) => {
        navigate(`/phy/${id}`);
    }

    useEffect(() => {
            queryDataPhi(arg)
            querySourcePhi(arg)
            queryTargetPhi(arg)
        }
        , [arg]);

    useEffect(() => {
            queryAuxPhiSource().then()
        }
        // eslint-disable-next-line
        , [sourcePhi]);

    useEffect(() => {
            queryAuxPhiTarget().then()
        }
        // eslint-disable-next-line
        , [targetPhi]);

    useEffect(() => {
            queryAuxPhiTarget().then()
        }
        // eslint-disable-next-line
        , [targetPhi]);

    if (dataPhi === undefined || sourcePhi === '' || targetPhi === '' || auxPhiSource.length !== sourcePhi.length || auxPhiTarget.length !== targetPhi.length) {
        return (
            <div className='phi'>
                {dataPhi['full_name']}
                <LinearProgress/>
            </div>

        );

    } else {
        let dot = CreateDot();
        const csvLink = CreateLinkCsv(dataPhi, sourcePhi, targetPhi)
        let rel = FoundRel(sourcePhi, targetPhi) as any
        if (Object.keys(relCheckBox).length !== rel.length) {
            let aux = {} as any
            for (let i = 0; i < rel.length; i++) {
                aux[rel[i]] = false
            }
            setRelCheckBox(aux)
        }
        rel = rel.map((elem: any, index: number) => {
            return <FormControlLabel style={{color: color[index], pointerEvents: "none"}} key={elem}
                                     control={<Checkbox style={{color: color[index], pointerEvents: "auto"}}
                                                        onClick={() => {
                                                            let aux = Object.assign({}, relCheckBox)
                                                            aux[elem] = !aux[elem];
                                                            setRelCheckBox(aux)
                                                        }}/>} label={elem}/>
        })

        if (pageView[1]) {
            return (
                <div className='phi'>
                    {dataPhi['full_name']}
                    <br/>
                    {getButtonGroup()}
                    <div className="linkcsv"><CSVLink {...csvLink}><img src={downloadCsv}
                                                                        alt="Download CSV"/></CSVLink></div>
                    <FormGroup>
                        {rel}
                    </FormGroup>
                    <div className="graph">
                        <Graphviz dot={dot}/>
                    </div>
                </div>

            );
        } else {
            const style = {
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
            };

            const listItem = [] as any

            let stringPhi = ''

            if (dataPhi['birth_year'] !== null) {
                stringPhi = stringPhi + 'Birth Year: ' + dataPhi['birth_year']
                if (dataPhi['birth_country'] !== null) {
                    stringPhi = stringPhi + 'in ' + dataPhi['birth_country']
                }
            }

            if (stringPhi !== '') {
                listItem.push(<ListItemText key={stringPhi} primary={stringPhi}/>)
                listItem.push(<Divider key={'birth'} sx={{bgcolor: "black"}}/>)
            }

            stringPhi = ''

            if (dataPhi['death_year'] !== null) {
                stringPhi = stringPhi + 'Death Year: ' + dataPhi['death_year']
                if (dataPhi['Death_country'] !== null) {
                    stringPhi = stringPhi + 'in ' + dataPhi['Death_country']
                }
            }

            if (stringPhi !== '') {
                listItem.push(<ListItemText key={stringPhi} primary={stringPhi}/>)
                listItem.push(<Divider key={'death'} sx={{bgcolor: "black"}}/>)
            }

            let flag = true

            for (let i = 0; i < targetPhi.length; i++) {
                stringPhi = ''

                if (targetPhi[i]['thesis_title'] !== null) {
                    stringPhi = stringPhi + 'Thesis Title: ' + targetPhi[i]['thesis_title'] + '\n'
                }
                if (targetPhi[i]['university'] !== null) {
                    stringPhi = stringPhi + 'University: ' + targetPhi[i]['university'] + '\n'
                }
                if (targetPhi[i]['year'] !== null) {
                    stringPhi = stringPhi + 'Year: ' + targetPhi[i]['year'] + '\n'
                }
                if (targetPhi[i]['country'] !== null) {
                    stringPhi = stringPhi + 'Country: ' + targetPhi[i]['country'] + '\n'
                }

                if (stringPhi !== '') {
                    if (flag) {
                        listItem.push(<p key={'Information on Degrees'}><b>Information on Degrees</b></p>)
                        flag = false
                    }
                    listItem.push(<ListItemText style={{cursor: 'pointer'}}
                                                onClick={() => goRouteId(targetPhi[i]['source'])} key={stringPhi}
                                                primary={stringPhi}/>)
                    listItem.push(<Divider key={'thesis_Target ' + i} sx={{bgcolor: "black"}}/>)
                }
            }

            flag = true

            for (let i = 0; i < sourcePhi.length; i++) {
                stringPhi = ''

                stringPhi = stringPhi + allPhiSource[i]['full_name'] + '\n'

                if (stringPhi !== '') {
                    if (flag) {
                        listItem.push(<p key={'Child'}><b>Children</b></p>)
                        flag = false
                    }
                    listItem.push(<ListItemText style={{cursor: 'pointer'}}
                                                onClick={() => goRouteId(allPhiSource[i][`phil_id`])} key={stringPhi}
                                                primary={stringPhi}/>)
                    listItem.push(<Divider key={'thesis_Source ' + i} sx={{bgcolor: "black"}}/>)
                }
            }

            return (
                <div className='phi'>
                    {dataPhi['full_name']}
                    <br/>
                    {getButtonGroup()}
                    <div className="dataPhi">
                        <List sx={style} component="nav" aria-label="mailbox folders">
                            {listItem}
                        </List>

                    </div>
                </div>

            );
        }
    }

    function queryDataPhi(arg: string) {
        fetch(variables.API_URL + 'philosophersById' + arg)
            .then(response => response.json())
            .then(data => {
                setDataPhi(Object.assign({}, data[0], data[1]));
            });
    }

    function querySourcePhi(arg: string) {
        fetch(variables.API_URL + 'edgesSource' + arg)
            .then(response => response.json())
            .then(data => {
                setSourcePhi(data);
            });
    }

    function queryTargetPhi(arg: string) {
        fetch(variables.API_URL + 'edgesTarget' + arg)
            .then(response => response.json())
            .then(data => {
                setTargetPhi(data);
            });
    }

    async function queryAuxPhiSource() {
        let response: any[] = []
        let responseAllPhi: any[] = []
        let argAux = ''
        for (let i = 0; i < sourcePhi.length; i++) {
            argAux = '?id=' + sourcePhi[i]['target']
            await fetch(variables.API_URL + 'philosophersById' + argAux)
                .then(response => response.json())
                .then(data => {
                    responseAllPhi[responseAllPhi.length] = Object.assign({}, data[0], data[1]);
                });
            await fetch(variables.API_URL + 'edgesSource' + argAux)
                .then(response => response.json())
                .then(data => {
                    response[i] = data
                })
        }

        for (let i = 0; i < response.length; i++) {
            for (let j = 0; j < response[i].length; j++) {
                argAux = '?id=' + response[i][j]['target']
                await fetch(variables.API_URL + 'philosophersById' + argAux)
                    .then(response => response.json())
                    .then(data => {
                        responseAllPhi[responseAllPhi.length] = Object.assign({}, data[0], data[1]);
                    });
            }
        }

        setAllPhiSource(responseAllPhi)
        setAuxPhiSource(response)
    }

    async function queryAuxPhiTarget() {
        let response: any[] = []
        let responseAllPhi: any[] = []
        let argAux = ''
        for (let i = 0; i < targetPhi.length; i++) {
            argAux = '?id=' + targetPhi[i]['source']
            await fetch(variables.API_URL + 'philosophersById' + argAux)
                .then(response => response.json())
                .then(data => {
                    responseAllPhi[responseAllPhi.length] = Object.assign({}, data[0], data[1]);
                });
            await fetch(variables.API_URL + 'edgesTarget' + argAux)
                .then(response => response.json())
                .then(data => {
                    response[i] = data
                })
        }

        for (let i = 0; i < response.length; i++) {
            for (let j = 0; j < response[i].length; j++) {
                argAux = '?id=' + response[i][j]['source']
                await fetch(variables.API_URL + 'philosophersById' + argAux)
                    .then(response => response.json())
                    .then(data => {
                        responseAllPhi[responseAllPhi.length] = Object.assign({}, data[0], data[1]);
                    });
            }
        }

        setAllPhiTarget(responseAllPhi)
        setAuxPhiTarget(response)
    }

    function CreateDot() {
        let strPhi = "\"" + dataPhi['countT'] + '\n' + dataPhi['full_name'] + '\n' + dataPhi['countS'] + "\""
        let tooltip = dataPhi['countT'] + '\n' + dataPhi['full_name'] + '\n' + dataPhi['countS']
        if (dataPhi['birth_year'] !== null) {
            tooltip = tooltip + '\nBirth Year: ' + dataPhi['birth_year']
        }
        if (dataPhi['death_year'] !== null) {
            tooltip = tooltip + '\nDeath Year: ' + dataPhi['death_year']
        }
        let dot = `digraph ""{\n
            class="allGraph"
            ratio="fill";\n
            size="5,3";\n
            center=True;\n
            node[fontsize="30", shape="box"];\n`
        if (sourcePhi.length === 0 && targetPhi.length === 0) {
            dot = dot + strPhi + ` \n[tooltip="` + tooltip + `" color = red];}`
        } else {
            dot = dot + strPhi + ` [tooltip="` + tooltip + `"];\n`
            let len = sourcePhi.length
            for (let i = 0; i < sourcePhi.length; i++) {
                let strPhi2 = "\"" + allPhiSource[i]['countT'] + '\n' + allPhiSource[i]['full_name'] + '\n' + allPhiSource[i]['countS'] + "\""
                dot = dot + strPhi + ` -> ` + strPhi2
                for (let k = 0; k < Object.keys(relCheckBox).length; k++) {
                    if (relCheckBox[Object.keys(relCheckBox)[k]] && sourcePhi[i]['label'] === Object.keys(relCheckBox)[k]) {
                        dot = dot + ` [color="` + color[k] + `"];\n`
                    }
                }
                tooltip = allPhiSource[i]['countT'] + '\n' + allPhiSource[i]['full_name'] + '\n' + allPhiSource[i]['countS']
                if (allPhiSource[i]['birth_year'] !== null) {
                    tooltip = tooltip + '\nBirth Year: ' + allPhiSource[i]['birth_year']
                }
                if (allPhiSource[i]['death_year'] !== null) {
                    tooltip = tooltip + '\nDeath Year: ' + allPhiSource[i]['death_year']
                }
                dot = dot + strPhi2 + ` [tooltip="` + tooltip + `" URL="/phy/` + sourcePhi[i]['target'] + `"];\n`
                for (let j = 0; j < auxPhiSource[i].length; j++) {
                    let strPhi3 = "\"" + allPhiSource[len]['countT'] + '\n' + allPhiSource[len]['full_name'] + '\n' + allPhiSource[len]['countS'] + "\""
                    dot = dot + strPhi2 + ` -> ` + strPhi3
                    for (let k = 0; k < Object.keys(relCheckBox).length; k++) {
                        if (relCheckBox[Object.keys(relCheckBox)[k]] && auxPhiSource[i][j]['label'] === Object.keys(relCheckBox)[k]) {
                            dot = dot + ` [color="` + color[k] + `"];\n`
                        }
                    }
                    tooltip = allPhiSource[len]['countT'] + '\n' + allPhiSource[len]['full_name'] + '\n' + allPhiSource[len]['countS']
                    if (allPhiSource[len]['birth_year'] !== null) {
                        tooltip = tooltip + '\nBirth Year: ' + allPhiSource[len]['birth_year']
                    }
                    if (allPhiSource[len]['death_year'] !== null) {
                        tooltip = tooltip + '\nDeath Year: ' + allPhiSource[len]['death_year']
                    }
                    len = len + 1
                    dot = dot + strPhi3 + ` [tooltip="` + tooltip + `" URL="/phy/` + auxPhiSource[i][j]['target'] + `"];\n`
                }
            }
            len = targetPhi.length
            for (let i = 0; i < targetPhi.length; i++) {
                let strPhi2 = "\"" + allPhiTarget[i]['countT'] + '\n' + allPhiTarget[i]['full_name'] + '\n' + allPhiTarget[i]['countS'] + "\""
                dot = dot + strPhi2 + ` -> ` + strPhi
                for (let k = 0; k < Object.keys(relCheckBox).length; k++) {
                    if (relCheckBox[Object.keys(relCheckBox)[k]] && targetPhi[i]['label'] === Object.keys(relCheckBox)[k]) {
                        dot = dot + ` [color="` + color[k] + `"];\n`
                    }
                }
                tooltip = allPhiTarget[i]['countT'] + '\n' + allPhiTarget[i]['full_name'] + '\n' + allPhiTarget[i]['countS']
                if (allPhiTarget[i]['birth_year'] !== null) {
                    tooltip = tooltip + '\nBirth Year: ' + allPhiTarget[i]['birth_year']
                }
                if (allPhiTarget[i]['death_year'] !== null) {
                    tooltip = tooltip + '\nDeath Year: ' + allPhiTarget[i]['death_year']
                }
                dot = dot + strPhi2 + ` [tooltip="` + tooltip + `" URL="/phy/` + targetPhi[i]['source'] + `"];\n`
                for (let j = 0; j < auxPhiTarget[i].length; j++) {
                    let strPhi3 = "\"" + allPhiTarget[len]['countT'] + '\n' + allPhiTarget[len]['full_name'] + '\n' + allPhiTarget[len]['countS'] + "\""
                    dot = dot + strPhi3 + ` -> ` + strPhi2
                    for (let k = 0; k < Object.keys(relCheckBox).length; k++) {
                        if (relCheckBox[Object.keys(relCheckBox)[k]] && auxPhiTarget[i][j]['label'] === Object.keys(relCheckBox)[k]) {
                            dot = dot + ` [color="` + color[k] + `"];\n`
                        }
                    }
                    tooltip = allPhiTarget[len]['countT'] + '\n' + allPhiTarget[len]['full_name'] + '\n' + allPhiTarget[len]['countS']
                    if (allPhiTarget[len]['birth_year'] !== null) {
                        tooltip = tooltip + '\nBirth Year: ' + allPhiTarget[len]['birth_year']
                    }
                    if (allPhiTarget[len]['death_year'] !== null) {
                        tooltip = tooltip + '\nDeath Year: ' + allPhiTarget[len]['death_year']
                    }
                    len = len + 1
                    dot = dot + strPhi3 + ` [tooltip="` + tooltip + `" URL="/phy/` + auxPhiTarget[i][j]['source'] + `"];\n`
                }
            }
            dot = dot + strPhi + ` [color = green]}`
        }

        return dot;
    }

    function getButtonGroup() {
        return <ButtonGroup className={'ButtonGroup'} variant="contained" aria-label="outlined primary button group">
            <Button onClick={() => setPageView([true, false])}>Data</Button>
            <Button onClick={() => setPageView([false, true])}>Graph</Button>
        </ButtonGroup>;
    }

    function CreateLinkCsv(phi: any, sourcePhi: any, targetPhi: any) {
        const headers = []
        for (let i = 0; i < Object.keys(phi).length; i++) {
            headers.push({label: Object.keys(phi)[i], key: Object.keys(phi)[i]})
        }
        headers.push({label: 'targets', key: 'targets'})
        headers.push({label: 'sources', key: 'sources'})

        const csvData = []

        let obj = {} as any
        for (let i = 0; i < headers.length; i++) {
            obj[headers[i]['key']] = phi[headers[i]['key']]
        }

        let aux = []
        for (let i = 0; i < sourcePhi.length; i++) {
            aux.push(sourcePhi[i]['target'])
        }

        obj['targets'] = aux
        aux = []

        for (let i = 0; i < targetPhi.length; i++) {
            aux.push(targetPhi[i]['source'])
        }
        obj['sources'] = aux

        csvData.push(obj)

        let len = sourcePhi.length
        let objAux = {} as any

        for (let i = 0; i < sourcePhi.length; i++) {
            obj = {}

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]['key']] = allPhiSource[i][headers[j]['key']]
            }
            aux = []
            for (let k = 0; k < auxPhiSource[i].length; k++) {
                objAux = {}
                for (let j = 0; j < headers.length; j++) {
                    objAux[headers[j]['key']] = allPhiSource[len][headers[j]['key']]
                }
                objAux['sources'] = allPhiSource[i]['phil_id']
                csvData.push(objAux)
                aux.push(allPhiSource[len]['phil_id'])
                len = len + 1
            }
            obj['targets'] = aux
            obj['sources'] = sourcePhi[i]['source']
            csvData.push(obj)
        }

        len = targetPhi.length

        for (let i = 0; i < targetPhi.length; i++) {
            obj = {}
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]['key']] = allPhiTarget[i][headers[j]['key']]
            }

            aux = []
            for (let k = 0; k < auxPhiTarget[i].length; k++) {
                objAux = {}
                for (let j = 0; j < headers.length; j++) {
                    objAux[headers[j]['key']] = allPhiTarget[len][headers[j]['key']]
                }
                objAux['targets'] = allPhiTarget[i]['phil_id']
                csvData.push(objAux)
                aux.push(allPhiTarget[len]['phil_id'])
                len = len + 1
            }
            obj['sources'] = aux
            obj['targets'] = targetPhi[i]['target']
            csvData.push(obj)
        }

        return {
            headers: headers,
            data: csvData,
            filename: phi['full_name'] + '_graph'
        }
    }

    function FoundRel(sourcePhi: any, targetPhi: any) {
        const rel = [] as any
        for (let i = 0; i < sourcePhi.length; i++) {
            if (!(sourcePhi[i]['label'] in rel) && sourcePhi[i]['label'] !== null) {
                rel.push(sourcePhi[i]['label'])
            }
            for (let k = 0; k < auxPhiSource[i].length; k++) {
                if (!(auxPhiSource[i][k]['label'] in rel) && auxPhiSource[i][k]['label'] !== null) {
                    rel.push(auxPhiSource[i][k]['label'])
                }
            }
        }
        for (let i = 0; i < targetPhi.length; i++) {
            if (!(targetPhi[i]['label'] in rel) && targetPhi[i]['label'] !== null) {
                rel.push(targetPhi[i]['label'])
            }
            for (let k = 0; k < auxPhiTarget[i].length; k++) {
                if (!(auxPhiTarget[i][k]['label'] in rel) && auxPhiTarget[i][k]['label'] !== null) {
                    rel.push(auxPhiTarget[i][k]['label'])
                }
            }
        }
        if (rel.includes('Unspecified')) {
            rel.unshift('Unspecified')
        }
        return Array.from(new Set(rel))
    }
}

export default Philosophers;